// ** Initial State
const initialState = {
  Comments: [],
  Comments_count: 0,
  Comments_countAll: 0,
  data: [],
  dataBycategory: [],
  Last: [],
  reaction: [],
  total: 1,
  params: {},
  single: null,
  error: null,
  hasMedia: [],
};
const PlaylistReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EMPTY_ALL_PLAYLIST":
      return {
        ...state,
        data: [],
        total: 1,
      };
    case "GET_ALL_PLAYLIST":
      return {
        ...state,
        hasMedia: action.data.filter((el) => el.media_count > 0),
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };

    case "EMPTY_ALL_PLAYLIST_BY_CATEGORY":
      return {
        ...state,
        dataBycategory: [],
        total: 0,
      };
    case "GET_ALL_PLAYLIST_BY_CATEGORY":
      return {
        ...state,
        hasMedia: action.data.filter((el) => el.media_count > 0),
        dataBycategory: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ALL_PLAYLIST_COMMENTS":
      return {
        ...state,
        Comments: action.data,
        Comments_countAll: action.totalPages,
      };
    case "GET_PLAYLIST_BY_ID":
      return {
        ...state,
        single: action.data,
        params: action.params,
      };
    case "END_EDIT_PLAYLIST":
      return {
        ...state,
        single: null,
      };
    case "GET_REACTION_BY_PLAYLIST":
      return {
        ...state,
        reaction: action.reaction,
      };
    case "GET_COMMENT_BY_PLAYLIST":
      return {
        ...state,
        Comments: action.Comments,
        Comments_count: action.Comments.filter((el) => el.active === true)
          .length,
      };
    case "GET_LAST_PLAYLIST":
      return {
        ...state,
        Last: action.Last,
      };
    case "EMPTY_ERROR":
    case "ADD_PLAYLIST_SUCCESS":
    case "EDIT_PLAYLIST_SUCCESS":
    case "ADD_COMMENT_PLAYLIST_SUCCESS":
    case "ADD_REACTION_PLAYLIST_SUCCESS":
      return { ...state, error: null };
    case "ADD_PLAYLIST_FAIL":
    case "ADD_COMMENT_PLAYLIST_FAIL":
    case "EDIT_PLAYLIST_FAIL":
    case "ADD_REACTION_PLAYLIST_FAIL":
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default PlaylistReducer;
