// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  error: null,
  deleted: [],
};
const PlanningCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_PLANNING_CATEGORIES":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
      };
      case "GET_DELETED_CATEGORY_SUCCESS":
        return {
          ...state,
          deleted: action.payload,
        };
    case "GET_PLANNING_CATEGORY":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        info: action.info,
      };
    case "EMPTY_ERROR":
    case "ADD_PLANNING_CATEGORY_SUCCESS":
    case "EDIT_PLANNING_CATEGORY_SUCCESS":
      return { ...state, error: null };
    case "ADD_PLANNING_CATEGORY_FAIL":
    case "EDIT_PLANNING_CATEGORY_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default PlanningCategoryReducer;
