const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  Comments: [],
  Comments_count: 0,
  Comments_countAll: 0,
  reaction: [],
  Last: [],
  error: null,
  single: null,
};

const PodcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PODCAST":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        allData: action.allData,
      };
    case "GET_ALL_PODCAST_COMMENTS":
      return {
        ...state,
        Comments: action.data,
        Comments_countAll: action.totalPages,
        params: action.params,
      };
    case "GET_LAST_PODCASTS":
      return {
        ...state,
        Last: action.Last,
      };
    case "GET_All_PODCASTS":
      return {
        ...state,
        data: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_FULL_PODCASTS":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMPTY_GET_FULL_PODCASTS":
      return {
        ...state,
        data: [],
        total: 0,
      };
    case "GET_PODCAST_ID":
      return {
        ...state,
        single: action.selectedPodcast,
      };

    case "GET_COMMENT_BY_PODCAST":
      return {
        ...state,
        Comments: action.Comments,
        Comments_count: action.Comments.filter((el) => el.active === true)
          .length,
      };
    case "GET_REACTION_BY_PODCAST":
      return {
        ...state,
        reaction: action.reaction,
      };

    case "EMPTY_ERROR":
    case "ADD_PODCAST_SUCCESS":
    case "EDIT_PODCAST_SUCCESS":
    case "ADD_COMMENT_PODCAST_SUCCESS":
    case "ADD_REACTION_PODCAST_SUCCESS":
      return { ...state, error: null };
    case "ADD_PODCAST_FAIL":
    case "EDIT_PODCAST_FAIL":
    case "ADD_COMMENT_PODCAST_FAIL":
    case "ADD_REACTION_PODCAST_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_PODCAST":
      return { ...state };
    default:
      return { ...state };
  }
};

export default PodcastReducer;
