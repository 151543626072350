// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  single: null,
  info: [],
  error: null,
  publicity: null,
  news: null,
  uploadLoading: false,
  softdeletedTOpics: [],
  all_topics: [],
  topic_by_user: [],
};
const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_TOPICS":
      return {
        ...state,
        all_topics: action.data,
      };
    case "GET_TOPIC_NEWS":
      return {
        ...state,
        news: action.data,
      };
    case "GET_TOPIC_PUBLICITY":
      return {
        ...state,
        publicity: action.data,
      };
    case "GET_TOPIC_BY_USER":
      return {
        ...state,
        topic_by_user: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_NEWS":
      return {
        ...state,
        allData: action.data,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        info: action.info,
      };
    case "EMPTY_GET_NEWS":
      return {
        ...state,
        data: [],
        total: 0,
      };
    case "GET_NEWS_ID":
      return {
        ...state,
        single: action.data,
        photo: action.photo,
      };
    case "EMPTY_ERROR":
    case "ADD_NEWS_SUCCESS":
    case "EDIT_NEWS_SUCCESS":
      return { ...state, error: null };
    case "ADD_NEWS_FAIL":
    case "EDIT_NEWS_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_NEWS":
      return { ...state, total: action.totalPages, params: action.params };
    case "UPLOAD_IS_LOADING":
      return { ...state, uploadLoading: true };
    case "UPLOAD_FINISHED_LOADING":
      return { ...state, uploadLoading: false };
    case "GET_SOFT_DELETED_TOPICS":
      return { ...state, softdeletedTOpics: action.data };
    default:
      return { ...state };
  }
};

export default NewsReducer;
