// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  LastTopics: [],
};
const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOPICS":
      return {
        ...state,
        data: action.data.data,
      };

    case "GET_LAST_TOPICS":
      return {
        ...state,
        LastTopics: action.LastTopics,
      };

    default:
      return { ...state };
  }
};

export default FilterReducer;
