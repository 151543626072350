const initialState = {
  quizzes: [],
  quiz_total: 0,
  quiz_count: 0,


  _quiz_total: 0,
  _quiz_count: 0,
  questionByQuizzId: [{ quetionsOption: [] }],
  allQuestion: [],
  _listquiz: [
    {
      id: 0,
      title: "",
      multiChoice: false,
      sumVotes: 0,
      answers: [{ opt_text: "", AnswersCount: 0 }]
    }
  ],
  _answersByQuiz: [{ opt_text: "", AnswersCount: 0 }],
  _getQuizByID: { title: "", _answers: [{ opt_text: "", AnswersCount: 0 }] },
  _QuizFormLastselectedIndex: -1,
  VoteRadioQuestionLastIndex: []
};
//quizz reducer
const QuizzReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_QUIZZ":
      return {
        ...state,
        quizzes: action.data,
        quiz_total: action.total,

        quiz_count: action.count
      };
    case "GET_ALL_QUESTION_BY_QUIZZ_ID":
      return {
        ...state,
        questionByQuizzId: action.data,
        VoteRadioQuestionLastIndex: []
      };
    case "GET_ALL_QUESTION":
      return { ...state, allQuestion: action.data.getAllQuestions };
    case "_GET_ALL_QUIZ":
      return { ...state, _listquiz: action.data ,  _quiz_total: action.total,
        _quiz_count: action.count };
    case "_GET_ANSWERS_BY_QUIZ_Id":
      return { ...state, _answersByQuiz: action.data };
    case "_GET_QUIZ_BY_ID":
      return {
        ...state,
        _getQuizByID: action.data,
        _QuizFormLastselectedIndex: -1
      };
    case "UPDATE_LAST_SELECTED_FORM_INDEX":
      return {
        ...state,
        _getQuizByID: action.data.quiz,
        _QuizFormLastselectedIndex: action.data.index
      };
    case "UPDATE_VOTE_QUESTION_FOR_STATS":
      return { ...state, questionByQuizzId: action.data };
    case "UPDATE_VOTE_LAST_INDEX":
      return { ...state, VoteRadioQuestionLastIndex: action.data };
    default:
      return state;
  }
};

export default QuizzReducer;
