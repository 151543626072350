// ** Initial State
const initialState = {
  allData: [],
  DefaultData: [],
  TodayByCountry: []
};
const StatistiqueReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_STATISTIQUE_BY_PARAMS":
      return {
        ...state,
        allData: action.data
      };
    case "GET_STATISTIQUE":
      return {
        ...state,
        DefaultData: action.data
      };
    case "GET_STATISTIQUE_BY_COUNTRY_TODAY":
      return{
      ...state,
      TodayByCountry: action.TodayByCountry
      }
    default:
      return { ...state };
  }
};

export default StatistiqueReducer;
