const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedEpisodes: null,
  allData: [],
  error: null,
  rushTypes: [],
  classifications: [],
  tags: [],
  classifications: [],
  rush_by_status: [],
  rushsByCategory: [],
  softDeleted: [],
  rush_by_type: [],
  rush_by_type_total: 0,
  upload_success: -1,
};

const RushsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_Rush":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        allData: action.allData,
      };
    case "GET_ALL_RUSHS":
      return {
        ...state,
        data: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_All_RUSHS_BY_TYPEID":
      return {
        ...state,
        rush_by_type: action.data,
        rush_by_type_total: action.total,
      };
    case "GET_All_FINISHED_RUSHS":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_All_RUSH_BY_STATUS_ID":
      return {
        ...state,
        rush_by_status: action.allData,
      };
    case "GET_SELECTED_RUSH":
      return { ...state, selectedEpisodes: action.selectedEpisodes };
    case "UPLOAD_SUCCESS_RECORD":
      return { ...state, upload_success: action.payload };
    case "GET_Rush_ID":
    case "EMPTY_ERROR":
    case "RECORD_RUSH_SUCCESS":
    case "IMPORT_RUSH_SUCCESS":
    case "EDIT_RUSH_SUCCESS":
    case "RECORD_NEW_RUSH_SUCCESS":
      return { ...state, error: null };
    case "GET_ALL_RUSH_TYPES":
      return { ...state, rushTypes: action.payload };
    case "RECORD_RUSH_FAIL":
    case "IMPORT_RUSH_FAIL":
    case "EDIT_RUSH_FAIL":
      return { ...state, error: action.payload };
    case "GET_ALL_RUSH_CLASSIFICATIONS":
      return { ...state, classifications: action.payload };
    case "GET_ALL_RUSH_TAGS":
      return { ...state, tags: action.payload };
    case "GET_ALL_CATHEGORIES_TAGS":
      return { ...state, cathegories: action.payload };
    case "GET_ALL_SOFT_DELETED_RUSH":
      return { ...state, softDeleted: action.payload };
    case "GET_RUSHS_BY_CATEGORY":
      return { ...state, rushsByCategory: action.payload };
    default:
      return { ...state };
  }
};

export default RushsReducer;
