// ** Initial State
const initialState = {
  media: [],
  data: [],
  mediaType: [],
  categories: [],
  params: {},
  single: null,
  mediaByCategory: [],
  params: {},
  total: 0,
  totalTags: 1,
};

const MediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MEDIA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "GET_MEDIA_BY_ID": {
      return {
        ...state,
        single: action.data,
      };
    }
    case "GET_MEDIA_TYPE":
      return {
        ...state,
        mediaType: action.data,
      };
    case "GET_TAGS":
      return {
        ...state,
        tag: action.data,
        totalTags: action.totalTags,
        params: action.params,
      };
    case "GET_CATEGORIES":
      return {
        ...state,
        categories: action.data,
      };
    case "GET_MEDIA_BY_TYPE_CATEGORY":
      return {
        ...state,
        mediaByCategory: action.data,
        total: action.totalPages,
        params: action.params,
      };
    default:
      return state;
  }
};

export default MediaReducer;
