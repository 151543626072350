// ** Initial State
const initialState = {
  Comments: [],
  Comments_count: 0,
  reaction: [],
  Last: [],
  data: [],
  total: 1,
  params: {},
  single: null,
  error: null,
};

const VideoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_VIDEOS":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMPTY_GET_ALL_VIDEOS":
      return {
        ...state,
        data: [],
        total: 0,
      };
    case "GET_ALL_VIDEO_COMMENTS":
      return {
        ...state,
        Comments: action.data,
        Comments_count: action.totalPages,
      };
    case "GET_COMMENT_BY_VIDEO":
      return {
        ...state,
        Comments: action.Comments.filter((el) => el.active === true),
        Comments_count: action.Comments.filter((el) => el.active === true)
          .length,
      };
    case "GET_REACTION_BY_VIDEO":
      return {
        ...state,
        reaction: action.reaction,
      };

    case "GET_LAST_VIDEO":
      return {
        ...state,
        Last: action.Last,
      };
    case "EMPTY_ERROR":
    case "EDIT_VIDEO_SUCCESS":
    case "ADD_COMMENT_VIDEO_SUCCESS":
    case "ADD_REACTION_VIDEO_SUCCESS":
      return { ...state, error: null };
    case "EDIT_VIDEO_FAIL":
    case "ADD_COMMENT_VIDEO_FAIL":
    case "ADD_REACTION_VIDEO_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_VIDEO":
    case "DOWNLOAD_VIDEO":
      return { ...state };
    case "GET_VIDEO_ID":
      return {
        ...state,
        single: action.data,
      };
    default:
      return { ...state };
  }
};

export default VideoReducer;
