// ** Initial State
const initialState = {
  data: [],
  data_media_id: [],
  total: 1,
  total_media_id: 1,
  params: {},
  error: null,
};
const CategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CATEGERY_MEDIA":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_ALL_CATEGERY_MEDIA_ID":
      return {
        ...state,
        data_media_id: action.data,
        total_media_id: action.totalPages,
        params: action.params,
      };
    case "EMPTY_ERROR":
    case "ADD_CATEGERY_MEDIA_SUCCESS":
    case "EDIT_CATEGERY_MEDIA_SUCCESS":
      return { ...state, error: null };
    case "ADD_CATEGERY_MEDIA_FAIL":
    case "EDIT_CATEGERY_MEDIA_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default CategoriesReducer;
