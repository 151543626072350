// ** Initial State
const initialState = {
  BannerSections: [],
  data: [],
  TypeBanners: [],
  total: 1,
  single: null,
  params: {},
  info: [],
  error: null,
};
const BackgroundReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BACKGROUND_RADIO_ID":
      return {
        ...state,
        data: action.data,
      };
    case "EMPTY_ERROR":
    case "ADD_BACKGROUND_SUCCESS":
    case "EDIT_BACKGROUND_SUCCESS":
      return { ...state, error: null };
    case "ADD_BACKGROUND_FAIL":
    case "EDIT_BACKGROUND_FAIL":
      return { ...state, error: action.payload };
  }
  return { ...state };
};

export default BackgroundReducer;
