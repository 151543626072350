// **  Initial State
const initialState = {
  data: [],
  params: {},
  total: 1,
  message: "",
  error: null,
};

const PermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PERMISSIONS_SUCCESS":
      return {
        ...state,
        data: action.payload,
        params: action.params,
      };
    case "GET_PERMISSIONS_FAIL":
      return {
        ...state,
        message: action.payload,
      };
    case "EMPTY_ERROR":
      return { ...state, error: null };
    default:
      return state;
  }
};

export default PermissionReducer;
