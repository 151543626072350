// ** Initial State
const initialState = {
  friendsByUserId: [],
  pendingFriendsByUserId: [],
  threadSenderAndReceiver: [],
};
const FriendReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_FRIEND_BY_USER_ID":
      return {
        ...state,
        friendsByUserId: action.data,
      };
    case "GET_PENDING_FRIEND_ID":
      return {
        ...state,
        pendingFriendsByUserId: action.data,
      };
    case "GET_SENDER_RECEIVER_FRIEND_ID":
      return {
        ...state,
        threadSenderAndReceiver: action.data,
      };
    case "INSERT_FRIEND_SUCCESS":
    case "REMOVE_FRIEND_SUCCESS":
      return { ...state, error: null };
    case "INSERT_FRIEND_FAILED":
    case "REMOVE_FRIEND_FAILED":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default FriendReducer;
