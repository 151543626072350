// ** Initial State
const initialState = {
  MenuSections: [],
  data: [],
  type: [],
  total: 1,
  single: null,
  params: {},
  error: null
};
const MenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MENUS":
      return {
        ...state,
        MenuSections: action.MenuSections,
        data: action.data,
        type: action.allType,
        total: action.totalPages
      };

    case "GET_MENU_ID":
      return {
        ...state,
        single: action.data
      };
    case "EMPTY_ERROR":
    case "ADD_MENU_SUCCESS":
    case "EDIT_MENU_SUCCESS":
    case "EDIT_STATUS_MENU_SUCCESS":
      return { ...state, error: null };
    case "ADD_MENU_FAIL":
    case "EDIT_MENU_FAIL":
    case "EDIT_STATUS_MENU_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_MENU":
      return { ...state, total: action.totalPages, params: action.params };
  }
  return { ...state };
};

export default MenuReducer;
