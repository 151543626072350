// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  info: [],
  single: {},
  error: null,
  active: null,
};
const DefaultPlsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DEFAULT_PLAYLIST":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        allData: action.data,
      };
    case "GET_ALL_DEFAULT_PLAYLIST":
      return {
        ...state,
        allData: action.data,
        data: action.data,
        params: action.params,
        total: action.totalPages,
      };
    case "GET_ACTIVE_DEFAULT_PLAYLIST":
      return {
        ...state,
        active: action.data,
      };
    case "GET_DEFAULT_PLAYLIST_ID":
      return {
        ...state,
        single: action.data,
      };
    case "EMPTY_ERROR":
    case "ADD_DEFAULT_PLAYLIST_SUCCESS":
    case "EDIT_DEFAULT_PLAYLIST_SUCCESS":
      return { ...state, error: null };
    case "ADD_DEFAULT_PLAYLIST_FAIL":
    case "EDIT_DEFAULT_PLAYLIST_FAIL":
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default DefaultPlsReducer;
