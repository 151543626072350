// ** Initial State
const initialState = {
  data: [],
  total: 0,
  params: {},
  error: null,
};

const JingleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_JINGLE":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMPTY_ERROR":
    case "ADD_JINGLE_SUCCESS":
    case "EDIT_JINGLE_SUCCESS":
      return { ...state, error: null };
    case "ADD_JINGLE_FAIL":
    case "EDIT_JINGLE_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_JINGLE":
    case "DOWNLOAD_JINGLE":
      return { ...state };
    default:
      return { ...state };
  }
};

export default JingleReducer;
