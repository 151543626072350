// **  Initial State
const initialState = {
  params: {},
  single: null,
  users: [],
  users_role: [{ roles: [{ name: "" }] }],
  getUserById: { first_name: "", last_name: "" },
  updateUserError: {
    address: [],
    citie_id: [],
    email: [],
    first_name: [],
    last_name: [],
    mobile: [],
    password: [],
    password_confirmation: [],
    region_id: [],
    role_id: [],
    state_id: [],
    username: [],
    zip_code: [],
  },
  searchUsers: [],
  canAddUser: true,
  roles_by_radio: [],
};

const RolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ROLES_SUCCESS_BY_RADIO_ID":
      return {
        ...state,
        roles_by_radio: action.payload,
      };
    case "GET_USERS_SUCCESS":
      return {
        ...state,
        users: action.payload,
        params: action.params,
      };
    case "GET_USERS_BY_ROLE_ID":
      return {
        ...state,
        users_role: action.payload,
      };
    case "GET_USER_BY_ID":
      return { ...state, getUserById: action.payload };
    case "EMPTY_ERROR":
      return { ...state, error: null, single: null };

    default:
      return state;
  }
};

export default RolesReducer;
