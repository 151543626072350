// ** Initial State
const initialState = {
  countries: [],
  stateByCountry: [],
  callingCodeByCountry: [],
  citiesByState: [],
  regionsByCity: [],
  zipCodeByRegion: [],
};
const AdressReducers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_COUNTRIES":
      return {
        ...state,
        countries: action.payload,
      };
    case "GET_STATE_BY_COUNTRY":
      return {
        ...state,
        stateByCountry: action.payload,
      };
    case "GET_CITY_STATE":
      return {
        ...state,
        citiesByState: action.payload,
      };
    case "GET_REGION_CITY":
      return {
        ...state,
        regionsByCity: action.payload,
      };
    case "GET_ZIP_REGION":
      return {
        ...state,
        zipCodeByRegion: action.payload,
      };
    case "GET_CALLING_CODE_BY_COUNTRY":
      return {
        ...state,
        callingCodeByCountry: action.payload,
      };
    default:
      return { ...state };
  }
};

export default AdressReducers;
