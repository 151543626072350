// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  single: null,
  info: [],
  error: null,
  Comments_count: 0,
};
const CommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMMENT_BY_WEB":
    case "GET_COMMENT_BY_TOPIC":
    case "GET_COMMENT_BY_SECTION":
    case "GET_COMMENT_BY_BLOG":
      return {
        ...state,
        allData: action.data.data,
        data: action.data.data,
        total: action.totalPages,
        params: action.params,
        info: action.info,
        Comments_count: action.data.data.filter((el) => el.status === 1).length,
      };
    case "ADD_COMMENT_SUCCESS":
    case "EDIT_COMMENT_SUCCESS":
      return { ...state, error: null };
    case "ADD_COMMENT_FAIL":
    case "EDIT_COMMENT_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default CommentReducer;
