// ** Initial State
const initialState = {
  participantByThreadId: [],
  participantById: null,
  participantId: null,
  userIdthreadId: {},
};
const ParticipantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PARTICIPANT_BY_THREAD_ID":
      return {
        ...state,
        participantByThreadId: action.data,
      };
    case "GET_PARTICIPANT_BY_ID":
      return {
        ...state,
        participantId: action.data,
      };
    case "GET_PARTICIPANT_BY_THREAD_ID_USER_ID":
      return {
        ...state,
        userIdthreadId: action.data,
      };
    case "BLOCK_UNBLOCK_PARTICIPANT_SUCCESS":
    case "INSERT_PARTICIPANT_SUCCESS":
      return { ...state, error: null, participantById: action.payload };
    case "BLOCK_UNBLOCK_PARTICIPANT_FAILED":
    case "INSERT_PARTICIPANT_FAILED":
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default ParticipantReducer;
