const initialState = {
    backoffice:{},
    Ctemplate:{getTemplateById:{contentWidith:[],MenuLayout:[],navbarrecolor:[]},routerTransition:[]},
    designbyTemplate:{skin:{id:0,skin:'light'},contentWidth:{id:0},menuLayout:{id:0},navBarreColore:{id:0,color:"danger"}}
  };



const themeReducer = (state = initialState, action) => {
   switch(action.type)
   {
    case"GET_ALL_TEMPLATES": return {...state,backoffice:action.data};
    case"GET_TEMPLATE_BY_ID":return {...state,Ctemplate:action.data};
    case"GET_DESIGN_BY_RADIO_ID":return {...state,designbyTemplate:action.data};
    
    default : return state
   }
  }

  export default themeReducer;