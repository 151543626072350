// ** Initial State
const initialState = {
  allData: [],
  data: [],
  params: {},
  recordsTotal: null,
};
const PhotoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PHOTO":
      return {
        ...state,
        allData: action.data,
        data: action.data,
        recordsTotal: action.recordsTotal,
        params: action.params,
      };
    case "EMPTY_GET_PHOTO":
      return {
        ...state,
        data: [],
        recordsTotal: 0,
      };
    case "GET_PHOTO_BY_USER":
      return {
        ...state,
        data: action.data,
        recordsTotal: action.recordsTotal,
      };
    default:
      return { ...state };
  }
};

export default PhotoReducer;
