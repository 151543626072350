// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  info: [],
  error: null,
};
const WebmasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WEBMASTER":
      return {
        ...state,
        allData: action.data,
        data: action.data,
        total: action.totalPages,
      };
  }
  return { ...state };
};

export default WebmasterReducer;
