// **  Initial State
const initialState = {
  allData: [],
  data: [],
  params: {},
  total: 1,
  message: "",
};

const ModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MODELS_SUCCESS":
      return {
        ...state,
        allData: action.data,
        data: action.data,
        total: action.total,
        params: action.params,
      };
    default:
      return state;
  }
};

export default ModalReducer;
