const initialState = {
  artistes: [],
  artisteDetail: {},
  params: {},
  totalArtiste: 0,
};

const artisteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ARTISTES":
      return {
        ...state,
        artistes: action.data,
        params: action.params,
        totalArtiste: action.totalPages,
      };

    case "GET_ARTISTE":
      return {
        ...state,
        artistes: action.data,
        params: action.params,
        totalArtiste: action.data.length,
      };
    default:
      return state;
  }
};

export default artisteReducer;
