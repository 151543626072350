const initialState = {
    getAllOptions:[],
    getOptionsByQuestionID:[]
  };
  //quizz reducer
  const OptionsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_OPTIONS": return {...state, getAllOptions : action.data};
      case "GET_OPTION_BY_QUESTION_ID": return {...state,getOptionsByQuestionID:action.data}
      default:
        return state;
    }
  };
  
  export default OptionsReducer;
  