import { combineReducers } from "redux";

// Layout
import navbar from "./layout/navbar";
import layout from "./layout/layout";
// Auth
import auth from "@store/reducer/auth/AuthReducer";
// Access_control
import AdressReducers from "@store/reducer/access_control/AdressReducers";
import RolesReducer from "@store/reducer/access_control/RolesReducer";
import UserReducers from "@store/reducer/access_control/UserReducers";
import PermissionReducer from "@store/reducer/access_control/PermissionReducer";
import ModalReducer from "@store/reducer/access_control/ModalReducer";
// Multimedia
import artisteReducer from "@store/reducer/multimedia/ArtistesReducer";
import albumReducer from "@store/reducer/multimedia/AlbumsReducer";
import AdsReducer from "@store/reducer/multimedia/AdsReducer";
import MediaReducer from "@store/reducer/multimedia/MediaReducer";
import CategorieReducer from "@store/reducer/multimedia/CategorieReducer";
import TagsReducer from "@store/reducer/multimedia/TagsReducer";
import SongReducer from "@store/reducer/multimedia/SongReducer";
import VideoReducer from "@store/reducer/multimedia/VideoReducer";
import JingleReducer from "@store/reducer/multimedia/JingleReducer";
import PlaylistReducer from "@store/reducer/multimedia/PlaylistReducer";
import CreditReducer from "@store/reducer/multimedia/CreditReducer";
import CategoriesReducer from "@store/reducer/multimedia/CategoriesReducer";
// Planning
import emissions from "@store/reducer/planning/EmissionReducer";
import episodes from "@store/reducer/planning/EpisodeReducer";
import calendar from "@store/reducer/planning/CalendarReducer";
import podcastReducer from "@store/reducer/planning/PodcastReducer";
import rushReducer from "@store/reducer/planning/RushsReducer";
import RushRoom from "@store/reducer/planning/RushRoomReducer";
import PlanningCategoryReducer from "@store/reducer/planning/PlanningCategoryReducer";
import PlanningClassificationReducer from "@store/reducer/planning/PlanningClassificationReducer";
import PlanningLanguageReducer from "@store/reducer/planning/PlanningLanguageReducer";
import PlanningTagReducer from "@store/reducer/planning/PlanningTagReducer";
// Radio
import TeamReducer from "@store/reducer/radio/TeamReducer";
import RadioReducer from "@store/reducer/radio/RadioReducer";
import DefaultPlsReducer from "@store/reducer/radio/DefaultPlsReducer";
// Smartend
import NewsReducer from "@store/reducer/smartend/NewsReducer";
import PhotoReducer from "@store/reducer/smartend/PhotoReducer";
import ContactReducer from "@store/reducer/smartend/ContactReducer";
import StatistiqueReducer from "@store/reducer/smartend/StatistiqueReducer";
import WebmasterReducer from "@store/reducer/smartend/WebmasterReducer";
import FilterReducer from "@store/reducer/smartend/FilterReducer";
import CategoryReducer from "@store/reducer/smartend/CategoryReducer";
import CommentReducer from "@store/reducer/smartend/CommentReducer";
import BackgroundReducer from "@store/reducer/smartend/BackgroundReducer";
import BannerReducer from "@store/reducer/smartend/BannerReducer";
import MenuReducer from "@store/reducer/smartend/MenuReducer";
import ContactGroupeReducer from "@store/reducer/smartend/ContactGroupe";
import SettingsReducer from "@store/reducer/smartend/SettingsReducer";
// Tickets
import TicketReducer from "@store/reducer/tickets/TicketReducer";
import TeamReducerr from "@store/reducer/tickets/TeamReducer";
import IdeaReducer from "@store/reducer/tickets/IdeaReducer";
import RequesterReducer from "@store/reducer/tickets/RequesterReducer";
import TagReducer from "@store/reducer/tickets/TagReducer";
// Player
import PlayerBackOfficeReducer from "@store/reducer/player/PlayerBackOfficeReducer";
import PlayerFrontOfficeReducer from "@store/reducer/player/PlayerFrontOfficeReducer";
// Chat
import GroupeChatReducer from "@store/reducer/chat/GroupeChatReducer";
import ParticipantReducer from "@store/reducer/chat/ParticipantReducer";
import MessageReducer from "@store/reducer/chat/MessageReducer";
import FriendReducer from "@store/reducer/chat/FriendReducer";
// Quizz
import QuizzReducer from "@src/redux/reducer/quizz/QuizzReducer";
import OptionsReducer from "./reducer/quizz/OptionsREducer";
// Widgets
import widgetsReducer from "./reducer/Widgets/widgetsReducer";
// Theme
import themeReducer from "./reducer/theme/themeReducer";
import FrontofficeThemeReducer from "./reducer/theme/FrontofficeThemeReducer";
// Icecastmonitor
import AudienceReducer from "@src/redux/reducer/icecastmonitor/audienceReducer";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  artisteReducer,
  albumReducer,
  AdsReducer,
  MediaReducer,
  CategorieReducer,
  TagsReducer,
  SongReducer,
  VideoReducer,
  JingleReducer,
  PlaylistReducer,
  CreditReducer,
  CategoriesReducer,
  emissions,
  episodes,
  calendar,
  podcastReducer,
  rushReducer,
  RushRoom,
  TeamReducer,
  RadioReducer,
  DefaultPlsReducer,
  NewsReducer,
  WebmasterReducer,
  CategoryReducer,
  CommentReducer,
  BackgroundReducer,
  FilterReducer,
  PhotoReducer,
  ContactReducer,
  RolesReducer,
  AdressReducers,
  UserReducers,
  PermissionReducer,
  StatistiqueReducer,
  ModalReducer,
  StatistiqueReducer,
  TicketReducer,
  TeamReducerr,
  IdeaReducer,
  RequesterReducer,
  PlayerFrontOfficeReducer,
  PlayerBackOfficeReducer,
  BannerReducer,
  MenuReducer,
  TagReducer,
  GroupeChatReducer,
  ParticipantReducer,
  MessageReducer,
  FriendReducer,
  ContactGroupeReducer,
  SettingsReducer,
  QuizzReducer,
  OptionsReducer,
  widgetsReducer,
  PlanningCategoryReducer,
  PlanningClassificationReducer,
  PlanningLanguageReducer,
  PlanningTagReducer,
  themeReducer,
  FrontofficeThemeReducer,
  AudienceReducer,
});

export default rootReducer;
