const initialState = {
  albums: [],
  albumsDetail: {},
  params: {},
  totalAlbums: 0,
};

const albumReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALBUMS":
      return {
        ...state,
        albums: action.data,
        params: action.params,
        totalAlbums: action.totalPages,
      };
    case "GET_ALBUM":
      return { ...state, albumsDetail: action.data };
    default:
      return state;
  }
};

export default albumReducer;
