// ** Initial State
const initialState = {
  openedthreadbyradioId: {},
  threadsByEpisodeId: {},
  threadsByRadioId: {},
  threadsByUserId: [],
  threadsByTypeId: [],
  searchthreadsByUserId: [],
  searchthreadsByTypeId: [],
  threadsbyId: {},
  threadById: {},
  error: null,
};
const GroupeChatReducer = (state = initialState, action) => {
  switch (action.type) {
    // NEW ACTIONS
    case "GET_THREAD_BY_ID":
      return { ...state, threadsbyId: action.payload };
    case "GET_OPENED_THREADS_BY_RADIO_ID":
      return { ...state, openedthreadbyradioId: action.payload };
    case "GET_THREADS_BY_EPISODE_ID":
      return { ...state, threadsByEpisodeId: action.payload };
    case "GET_THREADS_BY_RADIO_ID":
      return { ...state, threadsByRadioId: action.payload };
    case "GET_THREADS_BY_USER_ID":
      return { ...state, threadsByUserId: action.payload };
    case "GET_THREADS_BY_TYPE_ID":
      return { ...state, threadsByTypeId: action.payload };
    case "GET_SEARCH_THREADS_BY_USER_ID":
      return { ...state, searchthreadsByUserId: action.payload };
    case "GET_SEARCH_THREADS_BY_TYPE_ID":
      return { ...state, searchthreadsByTypeId: action.payload };

    case "INSERT_THREAD_SUCCESS":
      return { ...state, error: null, threadById: action.payload };
    case "OPEN_CLOSE_THREAD_SUCCESS":
    case "EDIT_THREAD_SUCCESS":
    case "REMOVE_THREAD_SUCCESS":
      return { ...state, error: null };
    case "OPEN_CLOSE_THREAD_FAILED":
    case "INSERT_THREAD_FAILED":
    case "EDIT_THREAD_FAILED":
    case "REMOVE_THREAD_FAILED":
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default GroupeChatReducer;
