// ** Initial State
const initialState = {
  messagesOpenedThread: null,
  messagesByThreadId: null,
  searchmessagesByThreadId: [],
  messagesByParticipantByThreadId: [],
  error: null,
};
const MessageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MESSAGE_BY_OPENED_THREAD_ID":
      return {
        ...state,
        messagesOpenedThread: action.data,
      };

    case "GET_MESSAGE_BY_THREAD_ID_SUCCESS":
      return {
        ...state,
        messagesByThreadId: action.data,
      };
    case "EMPTY_GET_MESSAGE_BY_THREAD_ID_SUCCESS":
      return {
        ...state,
        messagesByThreadId: null,
      };
    case "GET_SEARCH_MESSAGE_BY_THREAD_ID_SUCCESS":
      return {
        ...state,
        searchmessagesByThreadId: action.data,
      };
    case "GET_MESSAGE_BY_THREAD_PARTICIPANT_ID_SUCCESS":
      return {
        ...state,
        messagesByParticipantByThreadId: action.data,
      };

    case "INSERT_MESSAGE_SUCCESS":
      return { ...state, error: null };
    case "INSERT_MESSAGE_FAILED":
      return { ...state, error: action.payload };

    default:
      return { ...state };
  }
};

export default MessageReducer;
