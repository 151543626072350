// ** Initial State
const initialState = {
    data: [],
    total: 1,
    single: null,
    params: {},
    error: null
  };
  const ContactReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_CONTACTS_GROUPE":
        return {
          ...state,
          data: action.data,
          total: action.totalPages
        };
  
      case "GET_CONTACT_GROUPE_ID":
        return {
          ...state,
          single: action.data
        };
      case "EMPTY_ERROR":
      case "ADD_CONTACT_GROUPE__SUCCESS":
      case "EDIT_CONTACT_GROUPE_SUCCESS":
        return { ...state, error: null };
      case "ADD_CONTACT_GROUPE_FAIL":
      case "EDIT_CONTACT_GROUPE_FAIL":
        return { ...state, error: action.payload };
      case "DELETE_CONTACT_GROUPE":
        return { ...state, total: action.totalPages, params: action.params };
    }
    return { ...state };
  };
  
  export default ContactReducer;
  