// ** Initial State
const initialState = {
  BannerSections: [],
  data: [],
  TypeBanners: [],
  total: 1,
  single: null,
  params: {},
  info: [],
  error: null
};
const BannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BANNERS":
      return {
        ...state,
        BannerSections: action.data,
        data: action.data,
        total: action.totalPages
      };
    case "GET_BANNERS_TYPE":
      return {
        ...state,
        TypeBanners: action.TypeBanners
      };
    case "GET_BANNER_ID":
      return {
        ...state,
        single: action.data
      };
    case "EMPTY_ERROR":
    case "ADD_BANNER_SUCCESS":
    case "EDIT_BANNER_SUCCESS":
    case "EDIT_STATUS_BANNER_SUCCESS":
      return { ...state, error: null };
    case "ADD_BANNER_FAIL":
    case "EDIT_BANNER_FAIL":
    case "EDIT_STATUS_BANNER_FAIL":
      return { ...state, error: action.payload };
    case "DELETE_BANNER":
      return { ...state, total: action.totalPages, params: action.params };
  }
  return { ...state };
};

export default BannerReducer;
