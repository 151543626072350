// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  info: [],
  message: "",
  single: {},
  templates: [],
  template_by_id: null,
  design_by_radio: null,
  error: null,
};
const FrontofficeThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DESIGN_BY_RADIO_ID_":
      return {
        ...state,
        design_by_radio: action.data,
      };
    case "GET_ALL_FRONTOFFICE_TEMPLATES":
      return {
        ...state,
        templates: action.data,
      };
    case "GET_FRONTOFFICE_TEMPLATE_BYID":
      return {
        ...state,
        template_by_id: action.data,
      };
    case "EDIT_DESIGN_BY_RADIO_ID_SUCCESS":
      return { ...state, error: null };
    case "EDIT_DESIGN_BY_RADIO_ID_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default FrontofficeThemeReducer;
