// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  songsDownUrl: "",
  single: null,
  info: [],
  message: "",
  error: null,
};
const CreditReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CREDITS":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };
    case "GET_CREDIT_BY_ID":
      return {
        ...state,
        single: action.data,
      };
    case "EMPTY_ERROR":
    case "EDIT_CREDIT_SUCCESS":
      return { ...state, error: null };
    case "EDIT_CREDIT_FAIL":
      return { ...state, error: action.payload };
    case "DOWNLOAD_CREDIT":
      return { ...state, songsDownUrl: action.data };
    default:
      return { ...state };
  }
};

export default CreditReducer;
