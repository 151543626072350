// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  error: null,
};
const CategorieReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_CATEGORIES":
      return { ...state,         allData: action.allData,
        data: action.data,
        total: action.totalPages };
    case "GET_CATEGORIES":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        info: action.info,
      };
    case "EMPTY_ERROR":
    case "ADD_CATEGORY_SUCCESS":
    case "EDIT_CATEGORIES_SUCCESS":
      return { ...state, error: null };
    case "ADD_CATEGORY_FAIL":
    case "EDIT_CATEGORY_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default CategorieReducer;
