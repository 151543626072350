// ** Initial State
const initialState = {
  allData: [],
  Comments: [],
  Comments_count: 0,
  Comments_countAll: 0,
  data: [],
  Last: [],
  reaction: [],
  total: 1,
  params: {},
  podcastList: null,
  podcastbyepisode: null,
  error: null,
  commentError: null,
  em: null,
  emissionByCategory: [],
  emissionByClassification: [],
  emissionByTag: [],
  lastPage: 0,
  classificationTotal: 0,
  categoryTotal: 0,
  tagTotal: 0,
};

const emissions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_EMISSIONS_COMMENTS":
      return {
        ...state,
        Comments: action.data,
        Comments_countAll: action.totalPages,
        params: action.params,
      };
    case "EMPTY_GET_EMISSIONS":
      return {
        ...state,
        data: [],
        total: 0,
      };
    case "GET_EMISSIONS":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_EMISSION":
      return { ...state, selectedEmission: action.selectedEmission };
    case "GET_COMMENT_BY_EMISSION":
      return {
        ...state,
        Comments: action.Comments,
        Comments_count: action.Comments.filter((el) => el.active === true)
          .length,
      };

    case "GET_LAST_EMISSION":
      return {
        ...state,
        Last: action.Last,
      };
    case "GET_REACTION_BY_EMISSION":
      return {
        ...state,
        reaction: action.reaction,
      };
    case "GET_PODCAST_EMISSION_ID":
      return { ...state, podcastList: action.podcastList };
    case "GET_PODCAST_EPISODE_ID":
      return { ...state, podcastbyepisode: action.podcastbyepisode };
    case "GET_EMISSION_ID":
      return { ...state, em: action.emission };
    case "EMPTY_ERROR":
    case "ADD_EMISSION_SUCCESS":
    case "ADD_COMMENT_EMISSION_SUCCESS":
    case "EDIT_EMISSION_SUCCESS":
    case "ADD_REACTION_EMISSION_SUCCESS":
      return { ...state, error: null };
    case "ADD_EMISSION_FAIL":
    case "EDIT_EMISSION_FAIL":
    case "EDIT_COMMENT_EMISSION_FAIL":
    case "ADD_REACTION_EMISSION_FAIL":
      return { ...state, error: action.payload };
    case "ADD_COMMENT_EMISSION_SUCCESS":
      return { ...state, commentError: null };
    case "ADD_COMMENT_EMISSION_FAIL":
      return { ...state, commentError: action.payload };
    case "GET_EMISSION_BY_CATEGORY_SLUG":
      return {
        ...state,
        emissionByCategory: action.data,
        categoryTotal: action.total,
      };
    case "EMPTY_GET_EMISSION_BY_CATEGORY_SLUG":
      return { ...state, emissionByCategory: [] };
    case "GET_EMISSION_BY_TAG_SLUG":
      return { ...state, emissionByTag: action.data, tagTotal: action.total };
    case "EMPTY_GET_EMISSION_BY_TAG_SLUG":
      return { ...state, emissionByTag: [] };
    case "GET_EMISSION_BY_CLASSIFICATION_SLUG":
      return {
        ...state,
        emissionByClassification: action.data,
        lastPage: action.last_page,
        classificationTotal: action.total,
      };
    case "EMPTY_GET_EMISSION_BY_CLASSIFICATION_SLUG":
      return {
        ...state,
        emissionByClassification: [],
        lastPage: action.last_page,
      };
    case "EMISSION_PAGINATION_LAST_PAGE":
      return { ...state, lastPage: action.lastPage };
    case "DELETE_EMISSION":
      return { ...state };
    default:
      return { ...state };
  }
};
export default emissions;
