const initialState = { 
    getSourcesByMountPoint:{lastPage:0,data:[]},
    getPeackByDate:[],
    getListenersByDate:[]
  };
  //quizz reducer
  const AudienceReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_AUDIENCE_BY_MOUNTPOINT": return {...state, getSourcesByMountPoint : action.payload};
      case "GET_PEACK_BY_DATE_START": return {...state, getPeackByDate : action.payload};
      case "GET_LISTENERS_BY_DATE_START": return {...state, getListenersByDate : action.payload};
   
      default:
        return state;
    }
  };
  
  export default AudienceReducer;
  