// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    deletedRequester: [],
    status:[],
    error: null
  };
  const RequesterReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_REQUESTER":
        return {
          ...state,
          data: action.data,
          params: action.params,
          total: action.totalPages
        };
     
      case "GET_ALL_DELETED_REQUESTER":
        return {
          ...state,
          deletedRequester: action.data,
          params: action.params,
          total: action.totalPages
        };
      case "GET_REQUESTER_ID":
        return {
          ...state,
          single: action.data
        };
     
      default:
        return { ...state };
    }
  };
  
  export default RequesterReducer;
  