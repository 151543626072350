// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

// ** ThemeConfig Import
import themeConfig from "@configs/themeConfig";

const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem("menuCollapsed");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed;
};

const initialDirection = () => {
  const item = window.localStorage.getItem("direction");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.isRTL;
};

const initialSkin = () => {
  const item = window.localStorage.getItem("skin");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.skin;
};
const initialLang = () => {
  const item = window.localStorage.getItem("lang");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.lang;
};

const initialBanner = () => {
  const item = window.localStorage.getItem("banner");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : 1;
};
const initialBgColor = () => {
  const item = window.localStorage.getItem("tuneIn_theme");
  //** Parse stored json or if none return initialValue
  return item ? item : "white";
};
const initialNavBar = () => {
  const item = window.localStorage.getItem("tuneIn_navbar");
  //** Parse stored json or if none return initialValue
  return item ? item : 1;
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    banner: initialBanner(),
    bgColor: initialBgColor(),
    navbar: initialNavBar(),
    skin: initialSkin(),
    lang: initialLang(),
    isRTL: initialDirection(),
    layout: themeConfig.layout.type,
    lastLayout: themeConfig.layout.type,
    menuCollapsed: initialMenuCollapsed(),
    footerType: themeConfig.layout.footer.type,
    navbarType: themeConfig.layout.navbar.type,
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
    routerTransition: themeConfig.layout.routerTransition,
    navbarColor: themeConfig.layout.navbar.backgroundColor,
  },
  reducers: {
    handleRTL: (state, action) => {
      state.isRTL = action.payload;
      window.localStorage.setItem("direction", JSON.stringify(action.payload));
    },
    handleSkin: (state, action) => {
      state.skin = action.payload;
      window.localStorage.setItem("skin", JSON.stringify(action.payload));
    },
    handleLang: (state, action) => {
      state.lang = action.payload;
      window.localStorage.setItem("lang", JSON.stringify(action.payload));
    },
    handleLayout: (state, action) => {
      state.layout = action.payload;
    },
    handleFooterType: (state, action) => {
      state.footerType = action.payload;
    },
    handleNavbarType: (state, action) => {
      state.navbarType = action.payload;
    },
    handleMenuHidden: (state, action) => {
      state.menuHidden = action.payload;
    },
    handleLastLayout: (state, action) => {
      state.lastLayout = action.payload;
    },
    handleNavbarColor: (state, action) => {
      state.navbarColor = action.payload;
    },
    handleContentWidth: (state, action) => {
      state.contentWidth = action.payload;
    },
    handleMenuCollapsed: (state, action) => {
      state.menuCollapsed = action.payload;
      window.localStorage.setItem(
        "menuCollapsed",
        JSON.stringify(action.payload)
      );
    },
    handleRouterTransition: (state, action) => {
      state.routerTransition = action.payload;
    },
    handleBanner: (state, action) => {
      state.banner = action.payload;
      window.localStorage.setItem("banner", JSON.stringify(action.payload));
    },
    handleBgColor: (state, action) => {
      state.bgColor = action.payload;
      window.localStorage.setItem("tuneIn_theme", action.payload);
    },
    handleNavBar: (state, action) => {
      state.navbar = action.payload;
      window.localStorage.setItem("tuneIn_navbar", action.payload);
    },
  },
});

export const {
  handleRTL,
  handleSkin,
  handleLang,
  handleLayout,
  handleLastLayout,
  handleMenuHidden,
  handleNavbarType,
  handleFooterType,
  handleNavbarColor,
  handleContentWidth,
  handleMenuCollapsed,
  handleRouterTransition,
  handleBanner,
  handleBgColor,
  handleNavBar,
} = layoutSlice.actions;

export default layoutSlice.reducer;
