const initialState = {
  data: [],
  allData: [],
  params: {},
  totalRushs: 0
}

const RushStudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_All_CREATED_RUSHS':
      return { ...state, data: action.data, params: action.params, totalRushs: action.totalPages , allData:action.allData}

    default:
      return state
  }
}

export default RushStudioReducer
