const initialState = {
  currentSong: null,
  downoladUrl: null,
  namesong: null,
  songimg: "",
  playlist: [],
  item_data: null,
  has_video: false,
  name_id: null,
  is_web_rtc: false,
};

const PlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_MUSIC_FRONT":
      return {
        ...state,
        currentSong: action.payload.currentsong,
        namesong: action.payload.namesong,
        downoladUrl: action.payload.download,
        songimg: action.payload.songimg,
        item_data: action.payload.item_data,
        has_video: action.payload.has_video,
        name_id: action.payload.name_id,
        is_web_rtc: action.payload.is_web_rtc,
      };
    default:
      return state;
  }
};

export default PlayerReducer;
