// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  deletedIdeas: [],
  priority: [],
  comments: [],
  status: [],
  repository: [],
  error: null
};
const IdeasReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_IDEAS":
      return {
        ...state,
        data: action.data,
        params: action.params,
        total: action.totalPages
      };
    case "GET_ALL_PRIORITY":
      return {
        ...state,
        priority: action.data
      };
    case "GET_ALL_STATUS":
      return {
        ...state,
        status: action.data
      };

    case "GET_ALL_REPOSITORY":
      return {
        ...state,
        repository: action.data
      };
    case "GET_ALL_DELETED_IDEAS":
      return {
        ...state,
        deletedIdeas: action.data,
        params: action.params,
        total: action.totalPages
      };

    case "GET_COMMENTS_BY_IDEA_ID":
      return {
        ...state,
        comments: action.data
      };
    case "GET_IDEA_ID":
      return {
        ...state,
        single: action.data
      };
    case "EMPTY_ERROR":
    case "ADD_IDEA_SUCCESS":
    case "ADD_COMMENTS_IDEA_SUCCESS":
    case "EDIT_IDEA_SUCCESS":
    case "RESTORE_IDEAS_SUCCESS":
    case "EDIT_IDEA_STATUS_SUCCESS":
      return { ...state, error: null };
    case "ADD_IDEA_FAIL":
    case "ADD_COMMENTS_IDEA_FAIL":
    case "EDIT_IDEA_FAIL":
    case "EDIT_IDEA_STATUS_FAIL":
    case "RESTORE_IDEAS_FAIL":
      return { ...state, error: action.payload };
    default:
      return { ...state };
  }
};

export default IdeasReducer;
