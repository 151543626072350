// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  songsDownUrl: "",
  error: null,
};
const SongReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SONG":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "EMPTY_ERROR":
    case "EDIT_SONG_SUCCESS":
      return { ...state, error: null };
    case "EDIT_SONG_FAIL":
      return { ...state, error: action.payload };
    case "DOWNLOAD_DATA":
      return { ...state, songsDownUrl: action.data };
    default:
      return { ...state };
  }
};

export default SongReducer;
