// **  Initial State
const initialState = {
  userData: {},
  params: {},
  message: "",
  isAuth: false,
  error: null,
  errorPic: null,
  social_user: null,
  user_by_email: null,
  error_user_by_email: null,
  user_by_email_by_company: null,
  error_user_by_email_by_company: null,
  user_by_company_not_affected_to_product: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "VERIFY_SUCCESS":
      return {
        ...state,
        message: action.payload,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuth: true,
        userData: action.payload,
      };
    case "GET_USER__BY_EMAIL_SUCCESS":
      return {
        ...state,
        user_by_email: action.payload,
        error_user_by_email: null,
      };
    case "GET_USER__BY_EMAIL_SUCCESS_COMPANY":
      return {
        ...state,
        user_by_email_by_company: action.payload,
        error_user_by_email_by_company: null,
      };
    case "EMPTY_GET_USER__BY_EMAIL_SUCCESS":
      return {
        ...state,
        user_by_email: null,
        error_user_by_email: null,
      };
    case "EMPTY_GET_USER__BY_EMAIL_SUCCESS_COMPANY":
      return {
        ...state,
        user_by_email_by_company: null,
        error_user_by_email_by_company: null,
      };
    case "ERROR_GET_USER__BY_EMAIL_SUCCESS":
      return {
        ...state,
        error_user_by_email: action.payload,
      };
    case "ERROR_GET_USER__BY_EMAIL_FAILED_COMPANY":
      return {
        ...state,
        error_user_by_email_by_company: action.payload,
      };
    case "GET_USERS_BY_COMPANY_NOT_AFFECTED_TO_PRODUCT":
      return {
        ...state,
        user_by_company_not_affected_to_product: action.payload,
      };
    case "LOAD_USER_SUCCESS":
    case "GET_AUTH_USER_SUCCESS":
      return {
        ...state,
        userData: action.payload,
        isAuth: true,
        error: null,
      };
    case "UPLOAD_AVATAR_SUCCESS":
    case "UPDATE_PROFILE_SUCCESS":
    case "UPDATE_ACCOUNT_SUCCESS":
    case "CHANGE_PASSWORD_SUCCESS":
      return { ...state, error: null };
    case "LOGOUT":
      return { ...state, userData: {}, isAuth: false };
    case "RESET_PASSWORD":
    case "FORGET_PASSWORD":
      return { ...state, error: null, message: action.payload };
    case "UPLOAD_AVATAR_FAIL":
      return { ...state, errorPic: action.payload };
    case "LOGIN_FAIL":
    case "REGISTER_FAIL":
    case "FORGET_PASSWORD_FAIL":
    case "UPDATE_PROFILE_FAIL":
    case "UPDATE_ACCOUNT_FAIL":
    case "CHANGE_PASSWORD_FAIL":
    case "UPDATE_EMAIL_FAIL":
    case "RESET_PASSWORD_FAIL":
    case "ACCEPT_ACCOUNT_FAIL":
      return { ...state, error: action.payload };
    case "GET_ALL_PERMISSION":
      return { ...state, allData: action.data };
    case "EMPTY_ERROR":
      return { ...state, error: null };
    case "GET_SOCIAL_USER":
      return { ...state, social_user: action.payload };
    default:
      return state;
  }
};

export default authReducer;
