// ** Initial State
const initialState = {
  getAllTypeWidget:[{name:"",icon:'',is_active:false,frontOffice:"",widgets:[
     {
      Token:"",
      endPoint:"",
      is_reachebale:true
     }
  ]}]
   

   };
  const widgetsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_WIDGETS":
        return {
          ...state,
          getAllTypeWidget:action.data
        };
        case "GET_WIDGETS_BY_TYPE_ID":return{...state,widgetByType:action.data};
      default : return state
    }
  };
  
  export default widgetsReducer;
  