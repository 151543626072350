// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    deletedTeams: [],
    status:[],
    error: null
  };
  const TeamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_ALL_TEAMS":
        return {
          ...state,
          data: action.data,
          params: action.params,
          total: action.totalPages
        };
     
      case "GET_ALL_DELETED_TEAMS":
        return {
          ...state,
          deletedTeams: action.data,
          params: action.params,
          total: action.totalPages
        };
      case "GET_TEAM_ID":
        return {
          ...state,
          single: action.data
        };
     
      default:
        return { ...state };
    }
  };
  
  export default TeamsReducer;
  