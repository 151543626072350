// ** Initial State
const initialState = {
  data: null,
  total: 1,
  params: {},
  error: null,
};
const SettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WEBSITE_STYLE":
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
      };

    case "EMPTY_ERROR":
    case "EDIT_WEBSITE_STYLE_SUCCESS":
      return { ...state, error: null };
    case "EDIT__WEBSITE_STYLE_FAIL":
      return { ...state, error: action.payload };
  }
  return { ...state };
};

export default SettingsReducer;
