// ** Initial State
const initialState = {
  users_by_role_id: {},
  users_by_product_id:[],
  error: null,
};
const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_BY_ROLE_ID":
      return {
        ...state,
        users_by_role_id: action.payload,
      };
      case "GET_USERS_BY_PRODUCT_ID":
      return {
        ...state,
        users_by_product_id: action.payload,
      };
    case "EMPTY_ERROR":
    case "EDIT_USER_SUCCESS":
    case "CHANGE_PASSWORD_SUCCESS":
    case "FORGOT_PASSWORD_SUCCESS":
    case "RESET_PASSWORD_SUCCESS":
    case "ADD_USER_SUCCESS":
    case "REGISTERATION_SUCCESS":
      return {
        ...state,
        error: null,
      };
    case "EDIT_USER_FAIL":
    case "CHANGE_PASSWORD_FAIL":
    case "FORGOT_PASSWORD_FAIL":
    case "RESET_PASSWORD_FAIL":
    case "ADD_USER_FAIL":
    case "REGISTERATION_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};

export default UserReducer;
